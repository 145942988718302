


























































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Route, NavigationGuardNext } from "vue-router";
import {
  Col,
  Row,
  Image,
  Search,
  Tabs,
  Tab,
  NoticeBar,
  Swipe,
  SwipeItem,
  Grid,
  GridItem,
  Icon,
  PullRefresh,
  Popup,
  NavBar,
  Popover,
  Button,
  List,
  Dialog,
  // Toast
} from "vant";
import { Mixin } from "@/core/mixins/mixin";

import goodItem from "./component/GoodItem.vue";
import ticketItem from "./component/TicketItems.vue";
import orderBottom from "./component/OrderBottom.vue";
import EventBus from "@/utils/eventBus";
declare type status =
  | "ORDER_COMPLATE"
  | "PEND_PAYMENT"
  | "WAIT_SEND"
  | "TICKET_PAYABLE"
  | "WAIT_PICK_BYSELF"
  | "WAIT_GET_TICKET"
  | "CANCLE"
  | "WAITING_FOR_RECEIVED"
  | "ALL";
@Component({
  name: "orderList",
  components: {
    Mixin,
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Search.name]: Search,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Icon.name]: Icon,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Popover.name]: Popover,
    [Button.name]: Button,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
    ticketItem,
    goodItem,
    orderBottom,
    //  [Toast.Messa]:Toast.loading
  },
})
export default class orderList extends Mixins(Mixin) {
  // //判断是否是返回，不是就重新加载页面，是就是看缓存数据

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      let scrollTop = String((vm as orderList).scrollTop);
      let showText = String((vm as orderList).showText);
      let type = to.query.orderType!;
      if (from.path.includes("/mall/m-order")) {
        if (!!scrollTop && scrollTop !== "0") {
          (vm as orderList).scrollTop = Number(scrollTop);
        }
        if (!!showText) {
          (vm as orderList).showText = `${showText}`;
          (vm as orderList).onSelect({ text: showText });
        }
      } else {
        (vm as orderList).tabReset = false;
        (vm as orderList).showText =
          type && type === "ORDER_GOOD" ? `商品` : `全部`;

        let status = to.query.status as status;
        (vm as orderList).form.orderStatus = status;
        (vm as orderList).refreshActiveOrderList();
        (vm as orderList).scrollTop = 0;
        (vm as orderList).currentPage = 1;
      }
    });
  }
  public tabReset = true;
  public form: {
    timeFlag: number;
    orderStatus?:
      | "ORDER_COMPLATE"
      | "PEND_PAYMENT"
      | "WAIT_SEND"
      | "TICKET_PAYABLE"
      | "WAIT_PICK_BYSELF"
      | "WAIT_GET_TICKET"
      | "CANCLE"
      | "WAITING_FOR_RECEIVED"
      | "ALL";
    orderType: string;
  } = {
    // 近三个月
    timeFlag: 1,
    orderStatus: "ALL",
    orderType: "0",
  };
  public menuLists = [
    { name: "全部订单", id: "ALL" },
    { name: "待付款", id: "PEND_PAYMENT" },
    { name: "待发货", id: "WAIT_SEND" },
    { name: "待收货", id: "WAIT_GET_TICKET" },
    { name: "待取票", id: "WAIT_PICK_BYSELF" },
  ];
  public showPopover = false;
  // 通过 actions 属性来定义菜单选项
  public actions = [
    { text: "全部", color: "" },
    { text: "演出", color: "" },
    { text: "商品", color: "" },
  ];
  public showText = "全部";
  @Watch("showText", { deep: true, immediate: true })
  showTextChange(val: string): void {
    if (val === "商品") {
      this.menuLists = [
        { name: "全部订单", id: "ALL" },
        { name: "待付款", id: "PEND_PAYMENT" },
        { name: "待发货", id: "WAIT_SEND" },
        { name: "待收货", id: "WAIT_GET_TICKET" },
      ];
    } else {
      this.menuLists = [
        { name: "全部订单", id: "ALL" },
        { name: "待付款", id: "PEND_PAYMENT" },
        { name: "待发货", id: "WAIT_SEND" },
        { name: "待收货", id: "WAIT_GET_TICKET" },
        { name: "待取票", id: "WAIT_PICK_BYSELF" },
      ];
    }
    this.onSelect({ text: val });
  }
  @Watch("$route", { deep: true, immediate: true })
  routerChange(val: string): void {
    this.form.orderStatus = this.$route.query.status as status;
  }
  public showStyle = {
    width: "1rem",
    height: "0.5rem",
    "font-size": "0.28rem",
    "text-align": "center",
    padding: "0.1rem 0.2rem",
  };
  public vanKey = 1;

  public orderloading = false;
  public orderfinished = false;
  public reLoading = false;
  public tabShow = true;

  public nodata = false;
  public scrollTop = 0;
  public orderList: Array<ObjectMap> = [];

  public reset = true;
  public isBackFrom = "";
  public currentPage = 1; // 滚动分页，当前在第几页
  public pageFinished = false; // 滚动分页，是否已经没有数据了
  public finishedText = "没有更多数据";
  created() {
    EventBus.$on("refreshActiveOrderList", () => {
      this.refreshActiveOrderList();
    });
    EventBus.$on("saveParames", (val: ObjectMap) => {
      this.saveParames(val);
    });
    EventBus.$on("setOrderList", () => {
      this.orderList = [];
    });
  }
  activated() {
    this.scrollTo(String(this.scrollTop));
  }

  /**
   * 记录缓存 位置 和 分类 参数
   */
  saveParames(val: ObjectMap): void {
    this.scrollTop = val.scrollTop;
    this.showText = val.showText;
  }
  /**
   * 添加滚动事件
   */
  scrollTo(val: string) {
    this.$nextTick(() => {
      // setTimeout(() => {
      if (document.getElementsByClassName("van-tabs__content")[0]) {
        document.getElementsByClassName("van-tabs__content")[0].scrollTop =
          Number(val);
      }
      // }, 1000);
    });
  }

  onLoad(): void {
    this.currentPage++;
    this.orderloading = true;
    this.orderfinished = false;
    this.getOrderList(true);
  }

  refreshActiveOrderList() {
    let status = this.$route.query.status as status;
    this.form.orderStatus = status;
    this.orderList = [];
    this.onSelect({ text: this.showText });
    this.handleInit();
    this.$nextTick(() => {
      this.tabReset = true;
    });
  }
  refreshOrderList() {
    this.orderList = [];
    this.handleInit();
  }

  changeRouteStatus(): void {
    this.orderList = [];

    let url = "/personal-data/order?status=" + this.form.orderStatus;
    if (window.location.href.split("status=")[1] !== this.form.orderStatus) {
      this.$router.replace(url);
    }
    this.onSelect({ text: this.showText });
    this.handleInit();
  }
  onItemSelect(text: string): void {
    this.showText = text;
    this.showPopover = false;
    let status = this.$route.query.status as status;
    if (this.showText === "商品" && status === "WAIT_PICK_BYSELF") {
      this.form.orderStatus = "ALL";
      this.changeRouteStatus();
    } else {
      this.onSelect({ text: this.showText });
      this.orderList = [];
      this.handleInit();
    }
  }
  handleInit(): void {
    this.currentPage = 1;
    this.orderloading = true;
    this.orderfinished = false;
    this.getOrderList(true);
  }
  onSelect(action: { text: string }): void {
    //选中 右侧分类
    this.actions.forEach((item) => {
      if (item.text == action.text) {
        item.color = "#ffcd0f";
      } else {
        item.color = "#34495e";
      }
    });
  }

  getOrderList(first?: boolean) {
    if (first) {
      this.$api.orderApi.ticketOrder.getOrderList(
        {
          page: this.currentPage,
          size: 20,
          orderStatus:
            this.form.orderStatus !== "ALL" ? this.form.orderStatus : undefined,
          orderType:
            this.showText == "商品"
              ? "ORDER_GOOD"
              : this.showText == "演出"
              ? "ORDER_TICKET"
              : undefined,
        },
        ({ data }) => {
          this.orderloading = false;
          if (this.reLoading) {
            this.orderList = [];
            this.reLoading = false;
          }
          this.orderList =
            this.currentPage === 1
              ? data.records!
              : this.orderList.concat(data.records!);

          if (this.orderList.length === 0) {
            this.nodata = true;
          } else {
            this.nodata = false;
            //处理
            this.ordUnitPrice();
          }
          this.orderfinished = !!(this.orderList.length === data.total);
        }
      );
    }
  }
  ordUnitPrice(): void {
    this.orderList.forEach((item: any) => {
      let arr = new Array(2);
      item.payInfoList?.forEach((item: order.PayInfo) => {
        if (item.key === "积分") {
          arr[0] = item;
        }
        if (item.key === "印花") {
          arr[1] = item;
        }
      });
      arr = arr.filter((item) => item !== undefined);
      //票务
      let unitPrice =
        item.orderType !== "ORDER_GOOD"
          ? !!item.actuallyPaidAmt || item.actuallyPaidAmt === 0
            ? `<span class="m">¥</span><span class="maxSty">${
                item.actuallyPaidAmt.toFixed(2).split(".")[0]
              }</span>.<span class="minSty">${
                item.actuallyPaidAmt.toFixed(2).split(".")[1]
              }</span>`
            : ``
          : !!item.actuallyPaidAmt
          ? `<span class="m">¥</span><span class="maxSty">${
              item.actuallyPaidAmt.toFixed(2).split(".")[0]
            }</span>.<span  class="minSty">${
              item.actuallyPaidAmt.toFixed(2).split(".")[1]
            }</span>`
          : `` || "";

      if (arr.length !== 0) {
        if (!unitPrice && arr.length === 1) {
          arr.forEach((obj: ObjectMap) => {
            unitPrice = `${obj.price}&nbsp${obj.unit}`;
          });
        } else if (!unitPrice && arr.length === 2) {
          unitPrice = `<span class="maxSty">${arr[0].price}</span><span  class="minSty" >${arr[0].unit}</span>+<span class="maxSty"  >${arr[1].price}</span><span class="minSty">${arr[1].unit}</span>`;
        } else {
          arr.forEach((obj: ObjectMap) => {
            unitPrice = `<span class="maxSty">${obj.price}</span><span   class="minSty">${obj.unit}</span> + ${unitPrice}`;
          });
        }
      } else {
        unitPrice = `<span class="m">¥</span><span class="maxSty">${
          Number(item.actuallyPaidAmt).toFixed(2).split(".")[0]
        }</span>.<span  class="minSty">${
          Number(item.actuallyPaidAmt).toFixed(2).split(".")[1]
        }</span>`;
      }
      item.unitPrice = unitPrice;
    });
    this.onSelect({ text: this.showText }); //展示当前选中下拉的数据
  }

  onClickLeft(): void {
    if (this.isChannelDistribution) {
      // 渠道分销
      this.$router.replace(
        "/channeldistribution/" +
          (this.isCustomChannelDistribution ? "custom" : "common")
      );
    } else {
      this.$router.replace("/mine");
    }
  }
}
